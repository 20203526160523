import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import $ from "jquery"
import AlertsSlider from "../components/alertsslider"
import SEO from "../components/seo"

class PageTemplate extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeOrder = this.handleChangeOrder.bind(this)
    this.textChange = this.textChange.bind(this)
    this.goToPage = this.goToPage.bind(this)
    this.prevPage = this.prevPage.bind(this)
    this.nextPage = this.nextPage.bind(this)
    this.state = {
      departmentsId : `all`,
      documents: [],
      AllDocuments: ``,
      firstTime: 0,
      pageNum: 1,
      pagesNum: 1,
      currentDocs: []
    }
  }



  ChangeEndDate = (e) => {
    this.setState({
      endDate: e
    });
  };

  handleSubmit = event => {
     event.preventDefault()
     var allNews = this.state.AllDocuments
     var allDepartmentsIds = this.state.departmentsId
     var filteredDocs = []
     var tempItem

     if(this.state.titleSearch !== undefined && this.state.titleSearch !== '' && allDepartmentsIds !== '' && allDepartmentsIds !== 'all') {
        filteredDocs = allNews.filter(item => {

          return item.node.relationships.field_agency_relation.drupal_internal__id === parseFloat(allDepartmentsIds)
                  && (item.node.name.toLowerCase().indexOf(this.state.titleSearch.toLowerCase()) !== -1 || (item.node.field_document_title !== null && item.node.field_document_title.toLowerCase().indexOf(this.state.titleSearch.toLowerCase()) !== -1) )
        })
     } else if( (this.state.titleSearch === undefined || this.state.titleSearch === '') && allDepartmentsIds !== 'all') {
        filteredDocs = allNews.filter(item => {
          return item.node.relationships.field_agency_relation.drupal_internal__id === parseFloat(allDepartmentsIds)
        })
     } else if(this.state.titleSearch !== undefined && this.state.titleSearch !== '' && (allDepartmentsIds === '' || allDepartmentsIds === 'all') ) {
        filteredDocs = allNews.filter(item => {
          return item.node.name.toLowerCase().indexOf(this.state.titleSearch.toLowerCase()) !== -1 || (item.node.field_document_title !== null && item.node.field_document_title.toLowerCase().indexOf(this.state.titleSearch.toLowerCase()) !== -1)
        })
     } else if(allDepartmentsIds === 'all' && (this.state.titleSearch === undefined || this.state.titleSearch === '')) {
        filteredDocs = allNews
     }


      this.setState({
        documents: filteredDocs,
        AllDocuments: this.state.AllDocuments,
        departmentsId : this.state.departmentsId,
        titleSearch: this.state.titleSearch,
        firstTime: 1,
        pageNum: 1,
        pagesNum: Math.ceil(filteredDocs.length / 25),
        currentDocs: filteredDocs.slice(0,25)
      });

      const url = new URL(window.location);
      url.searchParams.set('guid', this.state.departmentsId);
      window.history.pushState({}, '', url);

   }

   handleChange = event => {
    this.setState({
      currentDocs: this.state.currentDocs,
      AllDocuments: this.state.AllDocuments,
      departmentsId: event.target.value,
      documents: this.state.documents,
      firstTime: 1,
      pageNum: this.state.pageNum,
      pagesNum: this.state.pagesNum,
      titleSearch: this.state.titleSearch
    })
  }

  handleChangeOrder = event => {

    if(event.target.id === 'order-from-select') {
      var arr = event.target.value.split('-')
      var name = arr[0]
      var order = arr[1]
    } else {
      var name = event.target.getAttribute('data-name')
      var order = event.target.getAttribute('data-order')
    }


    document.querySelector('.document-th').setAttribute('data-order','DESC')
    document.querySelector('.agency-th').setAttribute('data-order','DESC')
    document.querySelector('.modified-th').setAttribute('data-order','DESC')

    if(order === 'DESC') {
      event.target.setAttribute('data-order','ASC')
    } else {
      event.target.setAttribute('data-order','DESC')
    }

    document.querySelector('.document-th').firstElementChild.setAttribute('class','arrow-order')
    document.querySelector('.agency-th').firstElementChild.setAttribute('class','arrow-order')
    document.querySelector('.modified-th').firstElementChild.setAttribute('class','arrow-order')

    function sortDocumentsTitle(a,b) {
      var titleA, titleB;

        if(a.node.field_document_title) {
          titleA = a.node.field_document_title
        } else {
          titleA = a.node.name
        }
        if(b.node.field_document_title) {
          titleB = b.node.field_document_title
        } else {
          titleB = b.node.name
        }
      if (titleA < titleB)
          return -1;
      if (titleA > titleB)
          return 1;
      return 0;
    }

    function sortDocumnetsDate(a,b) {
      var titleA, titleB;
      titleA = a.node.created
      titleB = b.node.created
      if (titleA > titleB)
          return -1;
      if (titleA < titleB)
          return 1;
      return 0;
    }

    function sortDocumnetsAgency(a,b) {
      var titleA, titleB;
      if(a.node.relationships.field_agency_relation) {
        titleA = a.node.relationships.field_agency_relation.label
      }
      if(b.node.relationships.field_agency_relation.label) {
        titleB = b.node.relationships.field_agency_relation.label
      }
      if (titleA < titleB)
          return -1;
      if (titleA > titleB)
          return 1;
      return 0;
    }

    var sortDoc = [];
    if(name === 'title') {
      sortDoc = this.state.documents.sort(sortDocumentsTitle)
    } else if (name === 'date') {
      sortDoc = this.state.documents.sort(sortDocumnetsDate)
    } else if(name === 'agency') {
      sortDoc = this.state.documents.sort(sortDocumnetsAgency)
    }
    if(order === 'ASC') {
      sortDoc = sortDoc.reverse()
      event.target.firstElementChild.setAttribute('class','arrow-order active asc')
    } else {
      event.target.firstElementChild.setAttribute('class','arrow-order active')
    }

    this.setState({
      currentDocs: sortDoc.slice(0,25),
      AllDocuments: this.state.AllDocuments,
      departmentsId:  this.state.departmentsId,
      documents: sortDoc,
      firstTime: 1,
      pageNum: 1,
      pagesNum: this.state.pagesNum,
      titleSearch: this.state.titleSearch
    })
  }

   textChange = event => {
    this.setState({
      currentDocs: this.state.currentDocs,
      AllDocuments: this.state.AllDocuments,
      departmentsId: this.state.departmentsId,
      documents: this.state.documents,
      firstTime: 1,
      pageNum: this.state.pageNum,
      pagesNum: this.state.pagesNum,
      titleSearch: event.target.value
    })
  }

  prevPage = e => {
    var pageId = parseFloat(this.state.pageNum) - 1
    this.setState({
      currentDocs: this.state.documents.slice(parseFloat((pageId - 1) * 25), (pageId * 25)),
      AllDocuments: this.state.AllDocuments,
      departmentsId: this.state.departmentsId,
      titleSearch: this.state.titleSearch,
      documents: this.state.documents,
      firstTime: 1,
      pageNum: pageId,
      pagesNum: this.state.pagesNum
    })
  }

  nextPage = e => {
    var pageId = parseFloat(this.state.pageNum) + 1
    this.setState({
      currentDocs: this.state.documents.slice(parseFloat((pageId - 1) * 25), (pageId * 25)),
      AllDocuments: this.state.AllDocuments,
      departmentsId: this.state.departmentsId,
      titleSearch: this.state.titleSearch,
      documents: this.state.documents,
      firstTime: 1,
      pageNum: pageId,
      pagesNum: this.state.pagesNum
    })
  }


  goToPage = (e) => {
    var pageId = parseFloat(e.target.dataset.page)
    if(pageId === 1) {
      this.setState({
        currentDocs: this.state.documents.slice(0,25),
        AllDocuments: this.state.AllDocuments,
        departmentsId: this.state.departmentsId,
        titleSearch: this.state.titleSearch,
        documents: this.state.documents,
        firstTime: 1,
        pageNum: pageId,
        pagesNum: this.state.pagesNum
      })
    } else {
      //this.setState({ currentDocs: this.state.documents.slice(parseFloat((pageId - 1) * 10), (pageId * 10)) })
      this.setState({
        currentDocs: this.state.documents.slice(parseFloat((pageId - 1) * 25), (pageId * 25)),
        AllDocuments: this.state.AllDocuments,
        departmentsId: this.state.departmentsId,
        titleSearch: this.state.titleSearch,
        documents: this.state.documents,
        firstTime: 1,
        pageNum: pageId,
        pagesNum: this.state.pagesNum
      })
    }

  }

  componentDidMount() {
    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    };

    if(getUrlParameter('guid')) {
      this.setState( () => {
        return {
          documents: this.state.documents,
          AllDocuments: this.state.AllDocuments,
          departmentsId: getUrlParameter('guid'),
          firstTime: 1
        }
      })
      setTimeout(function(){
        $('#agency').val(getUrlParameter('guid')).trigger('change')
      },100)
      setTimeout(function(){
        $(".search-go").click()
      },200)
    }


  }


  render() {
    var moment = require('moment');
    const currentPage = this.props.data.nodePage
    const alerts = this.props.data.allNodeAlert
    const meta = currentPage.relationships.field_meta_data
    const allLandingPages = this.props.data.allNodeLandingPage.edges
    const filterAllDepartments = allLandingPages.filter(item => item.node.relationships.group_content__group_content_type_20b7e70e3cc2b && (item.node.field_include_in_list || item.node.relationships.field_parent_agency === null))
    var allDocumnetsTemp = this.props.data.allMediaDocument.edges.filter(item => (item.node.relationships.field_agency_relation !== null))

    if(this.state.firstTime === 0) {
      this.state = {
        AllDocuments: allDocumnetsTemp,
        departmentsId: this.state.departmentsId,
        titleSearch: this.state.titleSearch,
        documents: allDocumnetsTemp,
        firstTime: this.state.firstTime,
        pageNum: this.state.pageNum,
        pagesNum: Math.ceil(allDocumnetsTemp.length / 25),
        currentDocs: allDocumnetsTemp.slice(0,25)
      }

    } else {
      this.state = {
        AllDocuments: allDocumnetsTemp,
        departmentsId: this.state.departmentsId,
        titleSearch: this.state.titleSearch,
        documents: this.state.documents,
        firstTime: this.state.firstTime,
        pageNum: this.state.pageNum,
        pagesNum: this.state.pagesNum,
        currentDocs: this.state.currentDocs
      }
    }

    return (
        <Layout >
        <SEO
            title={currentPage.title}
            metaData={meta}
            contentBody={currentPage.body}
            locationOrigin={this.props.location.origin + currentPage.path.alias}
            domen={this.props.location.origin}
        />
        <div className="site-content document-center">
          <section className="uk-padding-remove-top">
            <AlertsSlider allAlerts={alerts.edges} gruopId={null} globalPage={true} />
          <div className="container uk-margin-medium-top">
            <h1>Document Center</h1>
              <form className="document-center-search" onSubmit={this.handleSubmit}>
                <div className="form-rows">
                  <label htmlFor="title-search-input"></label>
                  <input type="text" className="title-search" id="title-search-input" name="title-search" placeholder="Keyword Search" onChange={this.textChange} />
                  <label className="select-wrp" htmlFor="agency">
                  <select name="agency" id="agency" onChange={this.handleChange}>
                    <option defaultValue="selected" value="all">Select Agency</option>
                    {filterAllDepartments && filterAllDepartments.map((department,key) => (
                      <option key={"option"+key} value={department.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id}>{department.node.relationships.field_hero.field_title_html.value}</option>
                    ))}
                  </select>
                  </label>
                  <input className="search-go" type="submit" value="Go" />
                </div>
              </form>
              <div className="responsive-document-table">
                {this.state.currentDocs.length > 0 ? (
                  <>
                  <div className="select-order-wrp">
                    <select name="order" id="order-from-select" onChange={this.handleChangeOrder}>
                      <option value="form">Order by ...</option>
                      <option value="title-DESC">Order by title (ASC)</option>
                      <option value="title-ASC">Order by title (DESC)</option>
                      <option value="agency-DESC">Order by acency (ASC)</option>
                      <option value="agency-ASC">Order by acency (DESC)</option>
                      <option value="date-ASC">Order by date (ASC)</option>
                      <option value="date-DESC">Order by date (DESC)</option>
                    </select>
                  </div>
                  <table aria-label="documents table">
                    <thead>
                      <tr>
                        <th className="document-th table-header" scope="col" data-name="title" data-order="DESC" onClick={this.handleChangeOrder}>FORM / DOCUMENT <span className="arrow-order"></span></th>
                        <th className="agency-th table-header" scope="col" data-name="agency" data-order="DESC" onClick={this.handleChangeOrder}>AGENCY <span className="arrow-order"></span></th>
                        <th className="modified-th table-header" scope="col" data-name="date" data-order="ASC" onClick={this.handleChangeOrder}>MODIFIED<span className="arrow-order active"></span></th>
                        <th className="download-th" scope="col"></th>
                        <th className="info-th" scope="col"></th>
                      </tr>
                    </thead>
                    <tbody data-uk-accordion="content: + .ci-accordion-content .info-content; targets: > .main-row; multiple: true">

                          {this.state.currentDocs && this.state.currentDocs.map((doc,key) => {
                          return ([
                            <tr className="main-row" key={"document"+key}>
                              {doc.node.field_document_title ? (
                                <td data-label="FORM / DOCUMENT">{doc.node.field_document_title}</td>
                              ) : (
                                <td data-label="FORM / DOCUMENT">{doc.node.name}</td>
                              )}
                              <td className="small-gray-text" data-label="AGENCY">{doc.node.relationships.field_agency_relation.label}</td>
                              <td className="small-gray-text" data-label="MODIFIED">{moment(doc.node.created).format("MM/DD/YYYY")}</td>
                              {doc.node.relationships.field_media_document ? (
                                <td className="download-td" data-label="DOWNLOAD">
                                  <a className="download-btn" target="_blank" rel="noreferrer" href={doc.node.relationships.field_media_document.uri.url}>Download</a>
                                </td>
                              ) : (
                                <td></td>
                              )}

                              <td className="info-td uk-accordion-title" data-label="INFO">
                                {doc.node.relationships.field_document_description !== undefined ? (
                                <a className="info-btn" href="#"> </a>

                                ) : (
                                  null
                                )}
                              </td>
                            </tr>,
                            doc.node.relationships.field_document_description !== undefined ?
                            (
                              <tr className="ci-accordion-content" key={"document-second"+key}>
                                <td colSpan="5">
                                  <div className="info-content">
                                    <p>{doc.node.relationships.field_document_description}</p>
                                  </div>
                                </td>
                              </tr>
                            ) : (
                               <tr className="ci-accordion-content" key={"document-sec"+key}>
                                <td colSpan="5">
                                  <div className="info-content">

                                  </div>
                                </td>
                              </tr>
                            )
                          ])
                        })}
                    </tbody>
                  </table>
                  </>
                ) : (
                  <p>There no results found. Please try again with a different criteria.</p>
                )}


              </div>
              {this.state.documents && this.state.documents.length > 25 ? (
                <ul className="uk-pagination uk-flex-center">
                  {this.state.pageNum !== 1 ? (
                    <li className="uk-margin-auto-right" key="pagfirst">
                      <a className="prev" onClick={this.prevPage} href="#">Previous</a>
                    </li>
                    ) : (
                      <li className="uk-margin-auto-right" ket="pagfirst">

                      </li>
                  )}

                  {this.state.pageNum - 3 > 0 ? (
                    <>
                      <li key="pagfirst">
                        <a onClick={this.goToPage} data-page="1" href="#">1</a>
                      </li>
                      <li ket="pagdotfirst">
                      <span>...</span>
                      </li>
                    </>

                  ) : (
                    null
                  )}

                  {[...Array(this.state.pagesNum)].map((number,key) => (
                      key > this.state.pageNum - 4 && key < this.state.pageNum + 2 ? (
                        this.state.pageNum === key + 1 ? (
                          <li className="uk-active" key={"pag" + key}>
                            <a onClick={this.goToPage} data-page={key + 1} href="#">{key + 1}</a>
                          </li>
                        ) : (
                          <li key={"pag" + key}>
                            <a onClick={this.goToPage} data-page={key + 1} href="#">{key + 1}</a>
                          </li>
                        )
                      ) : (
                        null
                      )

                  ))}

                  {this.state.pageNum + 2 < this.state.pagesNum ? (
                    <>
                      <li key={"pagdot"}>
                      <span>...</span>
                      </li>
                      <li key={"paglast"}>
                        <a onClick={this.goToPage} data-page={this.state.pagesNum} href="#">{this.state.pagesNum}</a>
                      </li>
                    </>

                  ) : (
                    null
                  )}

                  {this.state.pageNum !== this.state.pagesNum ? (
                    <li className="uk-margin-auto-left" key={"pagprev"}>
                      <a className="next" href="#" onClick={this.nextPage}>Next</a>
                    </li>
                  ) : (
                    <li className="uk-margin-auto-left" key={"pagprev"}>

                    </li>
                  )}

                </ul>

              ) : (
                null
              )}

          </div> {/*container*/}
          </section>
        </div>

        </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    nodePage(id: { eq: $id }) {
      title
      body {
        value
      }
      path {
        alias
      }
      relationships {
        field_meta_data {
          ...paragraph__meta_dataFragment
        }
      }
    }
    allGroupDepartments(sort: {fields: label}) {
      edges {
        node {
          label
          drupal_internal__id
        }
      }
    }

    allNodeLandingPage(sort: {fields: relationships___field_hero___field_title_html___value}) {
      edges {
        ...node__landing_pageEdgeFragment
      }
    }

    allNodeAlert(filter: {field_global_post: {eq: true}},sort: {fields: field_weight, order: ASC}) {
      edges {
        node {
          title
          field_alert_text
          field_weight
          field_link {
            title
            uri
          }
          field_alert_type
          field_global_post
          relationships {
            group_content__departments_group_node_alert {
              relationships {
                gid {
                  drupal_internal__id
                }
              }
            }
          }
        }
      }
    }

    allMediaDocument(filter: {field_do_not_display_in_document: {ne: true}}, sort: {fields: created, order: DESC}) {
      edges {
        node {
          created
          field_document_description
          field_document_title
          name
          relationships {
            field_media_document {
              uri {
                url
              }
            }
            field_agency_relation {
              drupal_internal__id
              label
            }
          }
        }
      }
    }
  }
`
